import React, { useEffect, useState } from 'react';
import { Container, Row, Col, /* Spinner */ } from 'react-bootstrap';
import { finisherIcon, file, addFileWhite } from '../../assets';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import '../../css/formFinisher.css';
import { GovButton, GovFormInput } from '@gov-design-system-ce/react';
import { getCurrentDateAndTime } from '../../helperFunctions/helpers';

const FormFinisher = ({ header, id, isSuccessOrFailSubmission, linkRef, setStage, setIsFinished, finisherDetails, isOfficer = false }) => {
    console.log('Finisher details', finisherDetails);
    const [dateAndtime, setDateAndTime] = useState({
        dateFinal: '',
        timeFinal: ''
    });
    const location = useLocation();
    const navigate = useNavigate();
    const isDocumentation = linkRef === 'documentation';
    // TODO to check if this is relevant
    const tokenForNewVersion = '';
    const isExternalDocumentation = isDocumentation && Boolean(tokenForNewVersion);
    const [showCopySuccess, setShowCopySuccess] = useState(false);
    const { status, message } = isSuccessOrFailSubmission || {};
    const attachmentError = (message || '').toUpperCase().startsWith('ATTACHMENT');

    useEffect(() => {
        if (showCopySuccess) {
            const timer = setTimeout(() => {
                setShowCopySuccess(false);
            }, 60000);

            return () => clearTimeout(timer);
        }
    }, [showCopySuccess]);

    useEffect(() => {
        if (!status) {
            return;
        }

        const {formattedDate, formattedTime} = getCurrentDateAndTime();
        setDateAndTime(prev => ({ ...prev, timeFinal: formattedTime, dateFinal: formattedDate }));
    }, [status]);

    const handleNavigate = () => {
        if (isDocumentation) {
            navigate(`/dokumentace/new/${id}`, { state: { from: location, attachmentError } });
            if (setStage) setStage(1);
        } else {
            navigate(`/zadost/${linkRef}/${id}`, { state: { from: location, attachmentError } });
            if (linkRef === 'form18') {
                if (setStage) setStage(1);
            } else {
                if (setStage) setStage(2);
            }
        }
        
        setIsFinished(false);
    };
    
    return (
        <Container className='d-flex flex-column justify-content-center align-items-center text-center m-5' fluid>
            {status &&
                <Row className='m-4'>
                    <Col><img src={finisherIcon} style={{ width: '80px', height: '80px' }} /></Col>
                </Row>}

            {/* {status === null && <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>} */}
            {status === null && <Row className='row-wrapper'>
                <Col className='loader'>
                </Col>
            </Row>}

            <Row className='text-center'>
                <Col><p style={{ 'fontSize': '23px' }}>{isExternalDocumentation ? 'Dokumentace' : header}</p></Col>
            </Row>

            {status && <>
                <Row className='my-4'>
                    <Col><p style={{ 'fontSize': '32px' }}>{isDocumentation ? 'Vložena' : 'Úspěšně podána'}</p></Col>
                </Row>
                <Row className='row-wrapper d-flex flex-column align-items-center justify-content-center'>
                    <Col className='py-1'><p>{dateAndtime.dateFinal} v {dateAndtime.timeFinal} {!isDocumentation ? ` s dočasným identifikátorem ${id}` : ''}</p></Col>
                    {isDocumentation && <>
                        <Col className='py-1'><p>Číslo záměru: {finisherDetails?.icsId ?? ''}</p></Col>
                        <Col className='py-1'><p>ID dokumentace: {finisherDetails?.bppId ?? ''}</p></Col>
                        <Col className='py-1' sm={6}><p className='p-0 dokumentace-instruction_text'>ID dokumentace slouží k jednoznačné identifikaci konkrétní verze vložené dokumentace. V případě listinného podání žádosti, prosím, uveďte ID dokumentace do Vaší žádosti. Prostřednictvím ID dokumentace získá stavební úřad, nebo dotčený orgán přístup k příslušné verzi Vaší dokumentace.</p></Col>
                    </>}
                </Row>
                {!isOfficer && (
                    <>
                        {!isDocumentation && <Row className='row-wrapper'>
                            <Col>
                                <p className='confirmation_text'>Potvrzení podání a možnost stažení duplikátu žádosti najdete na stránce “Žádosti”.</p>
                            </Col>
                        </Row>}
                        <Row className='documentation-finisher-btn'>
                            <Col className='w-100'>
                                <Container className='pb-5'>
                                    <GovButton
                                        variant="primary"
                                        type="solid"
                                        size="m"
                                        onGov-click={() => navigate(isDocumentation ? '/dokumentace' : '/zadosti')}
                                    >
                                        {isDocumentation ? 'Dokumentace' : 'Moje žádosti'}
                                    </GovButton>
                                </Container>
                            </Col>
                        </Row>
                    </>
                )}
                {(isDocumentation && finisherDetails?.urlToCopy?.trim()) && <Row className='documentation-finisher-btn'>
                    <Col className='w-100'>
                        {isOfficer && <Container className='pb-2'>
                            <p className='confirmation_text'>Dokumentace byla vložena do spisové služby, můžete zavřít tuto záložku prohlížeče.</p>
                        </Container>}
                        {!isOfficer && <Container className='pb-2'>
                            <GovFormInput
                                disabled
                                value={finisherDetails?.urlToCopy?.trim()}
                            />
                        </Container>}
                        {!isOfficer && <Container className='pb-2'>
                            <GovButton
                                nativeType='button'
                                className='label-copy-button my-0'
                                type='outlined'
                                variant='primary'
                                size='m'
                                onGov-click={async () => {
                                    try {
                                        if (!finisherDetails?.urlToCopy?.trim()) {
                                            return;
                                        }
                                        await navigator.clipboard.writeText(finisherDetails?.urlToCopy?.trim());
                                        setShowCopySuccess(true);
                                    } catch (err) {
                                        setShowCopySuccess(false);
                                    }
                                }}
                            >
                                Kopírovat odkaz pro sdílení
                            </GovButton>
                            <p className='p-0 pt-2 dokumentace-instruction_text'>Pomocí odkazu pro sdílení můžete zpřístupnit Vámi vloženou dokumentaci jinému uživateli portálu stavebníka (např. projektantovi, jinému stavebníkovi či zástupci).</p>
                        </Container>
                        }
                    </Col>
                    <Row>
                        {showCopySuccess && (
                            <Col className='align-self-end'>
                                <p className='gov-color--success-400 gov-text--s pb-0 pb-lg-2 pe-2 ps-2 ps-lg-4 pt-2 pt-lg-0'>Odkaz úspěšně zkopírován do schránky!</p>
                            </Col>
                        )}
                    </Row>
                </Row>}
            </>}
            {status === null ?
                <Row>
                    <Col>
                        <p className='row-wrapper submissionInProgress' style={{marginTop: '40px'}}>Probíhá podání</p>
                        <p className='help_text'>{`Probíhá podání ${isDocumentation ? 'dokumentace' : 'žádosti'}, nezavírejte prohlížeč.`}</p>
                    </Col>
                </Row>
                : status ?
                    <>
                        {!isDocumentation && <Row className='m-5'>
                            <Col className='mb-5 text-center'>
                                <button className='button-more-info m-2 mx-4b d-none' style={{ height: '48px', width: '276px' }}><img src={file} />&nbsp;Stáhnout žádost</button>
                                <button className='button-fill-in d-none' style={{ height: '48px', width: '276px' }}><img src={addFileWhite} />&nbsp;Stáhnout žádost</button>
                            </Col>
                        </Row>}
                    </>
                    :
                    <Row className='m-5'>
                        <Col className='fst-italic text-center'>
                            {attachmentError ?
                                <p>
                                    Žádost nebylo možné podat z důvodu chyby nahrávání souborů. <br />
                                    Prosím, zkontroluje nahrané soubory v žádosti v části Přílohy, případně Dokumentace (je-li součástí žádosti) a zkuste žádost podat znovu.
                                </p>  
                                : isDocumentation ? 
                                    <p>
                                        Dokumentace nelze vložit <br />
                                        Prosím zkuste znovu.
                                    </p> 
                                    : <p>
                                        Žádost nelze odeslat, přihlášený uživatel nebyl nalezen mezi účastníky řízení <br />
                                        Opravte údaje a odešlete žádost znovu.
                                    </p>}
                        </Col>
                        <Row className='m-5'>
                            <Col className='mb-5 text-center'>
                                <button type='button' className='button-fill-in mr-5' style={{ height: '48px', width: '276px', marginRight: '100px', fontSize: '15px' }} onClick={handleNavigate}>{`Vrátit se zpět na ${isDocumentation ? 'dokumentaci' : 'formulář'}`}</button>
                            </Col>
                        </Row>
                    </Row>
            }
        </Container>
    );
};

FormFinisher.propTypes = {
    header: PropTypes.string,
    id: PropTypes.string,
    isSuccessOrFailSubmission: PropTypes?.any,
    linkRef: PropTypes.string,
    setStage: PropTypes.func,
    setIsFinished: PropTypes.func.isRequired,
    finisherDetails: PropTypes.any,
    isOfficer: PropTypes?.bool
};

export default FormFinisher;
