import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import { ZamerComponent, RozsahComponent, FormProgress, PrijemceComponent, ZadostPovoleniStavbyOsnova4, ZadostPovoleniStavbyOsnova5b, Dokumentace, ZadatelComponent, KontrolaComponent, PrilohyComponent } from '../index.js';
import { AuthContext } from 'react-oauth2-code-pkce';
import { formSave } from '../../apiCalls/formApiCalls';
import { FORM_04_INTENTION } from '../../constants/sharedConstants.js';
import FormContext from '../../formContexts/FormContext.js';
import { getUploadedPruvodniList, isDokumentace, isPrilohy, sendAttachmentRequest, sendDocumentationRequest } from '../../helperFunctions/helpers.js';
import axios from 'axios';

const ZadostPovoleniStavbyMainSection = ({
    stage,
    checkbox,
    setCheckbox,
    attachments,
    setAttachments,
    isMobileView,
    documents,
    setDocuments,
}) => {
    const [loading, setLoading] = useState(true);
    const { token } = useContext(AuthContext);
    const stepsRef = useRef([]);
    const urlPath = 'quick-save-api/build-application-04/save';
    let { id: urlId } = useParams();    
    const [foundApplicantIndex, setFoundApplicantIndex] = useState(null);
    const [applicantArr, setApplicantArr] = useState([]);
    const {setId, stepValue, stagesArr, id, setWaitingForPruvodniList} = useContext(FormContext);
    const documentsToSaveManually = React.useMemo(() => 
        [...(Object.values(documents || {})?.flat() || [])].filter(fileObj => (!fileObj.blobFile && !fileObj.abortController && !fileObj.message && !fileObj.hasError)), 
    [documents]);
    const attachmentsToSaveManually = React.useMemo(() => 
        [...(Object.values(attachments || {})?.flat()?.filter(el => !el.section?.startsWith('power_of_attorney')) || [])]
            .filter(fileObj => (!fileObj.blobFile && !fileObj.abortController && !fileObj.message && !fileObj.hasError)), 
    [attachments]);
    const location = useLocation();
    const attachmentError = location?.state?.attachmentError ?? false;

    useEffect(() => {
        if (!urlId) {
            (async () => {
                let data = JSON.stringify({
                    'buildApplication': {
                        'title': FORM_04_INTENTION.title
                    }
                });

                const response = await formSave(data, token, urlPath);
                if ((200 <= response?.status) && (response?.status < 300)) {
                    setId(response.data);
                    setLoading(false);
                }
            })();
        } else {
            setId(urlId);
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (!documentsToSaveManually.length || isDokumentace(stepValue, stagesArr) || attachmentError) {
            return; 
        }
        const source = axios.CancelToken.source();
        const requestObject = sendDocumentationRequest(id, [...(Object.values(documents || {}) || []).flat().filter(el => !el.message), ...documentsToSaveManually]);
        if (requestObject) {
            formSave(requestObject, token, urlPath, source);
        }
        if (setWaitingForPruvodniList) setWaitingForPruvodniList(!getUploadedPruvodniList(documentsToSaveManually));
    }, [documentsToSaveManually.length, attachmentError]);

    useEffect(() => {
        if (!attachmentsToSaveManually.length || isPrilohy(stepValue, stagesArr) || attachmentError) {
            return; 
        }
        const source = axios.CancelToken.source();
        const requestObject = sendAttachmentRequest(id, [...(Object.values(attachments|| {}) || []).flat().filter(el => !el.message), ...attachmentsToSaveManually]);
        if (requestObject) {
            formSave(requestObject, token, urlPath, source);
        }
    }, [attachmentsToSaveManually.length, attachmentError]);

    return (
        <Container fluid className='d-flex justify-content-center px-3 pt-0 pb-3 p-lg-5'>
            <Row className='base-width'>
                <Col className='d-flex justify-content-center justify-content-md-end d-md-block d-lg-none mb-4 mb-lg-0'>
                    {isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
                <Col xs={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }} className='pe-lg-3'>
                    {checkbox.first && checkbox.second && loading === false ? (
                        <>
                            {stage === 1 && <ZamerComponent
                                documents={documents}
                                setDocuments={setDocuments}
                                urlPath={urlPath}
                                setCheckbox={setCheckbox}
                            />}
                            {stage === 2 && <ZadatelComponent
                                urlPath={urlPath}
                                stage={stage}
                                foundApplicantIndex={foundApplicantIndex}
                                applicantArr={applicantArr}
                                setApplicantArr={setApplicantArr}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                            />}
                            {stage === 3 && <RozsahComponent
                                setCheckbox={setCheckbox}
                                urlPath={urlPath}
                            />}
                            {stage === 4 && <ZadostPovoleniStavbyOsnova5b
                                urlPath={urlPath}
                            />}
                            {stage === 5 && <ZadostPovoleniStavbyOsnova4
                                urlPath={urlPath}
                            />}
                            {stage === 6 && <Dokumentace
                                urlPath={urlPath}
                                documents={documents}
                                setDocuments={setDocuments}
                            />}
                            {stage === 7 && <PrilohyComponent
                                attachments={attachments}
                                setAttachments={setAttachments}
                                urlPath={urlPath}
                                stepsRef={stepsRef}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                                applicantArr={applicantArr}
                            />}
                            {stage === 8 && <PrijemceComponent
                                urlPath={urlPath}
                            />}
                            {stage === 9 && <KontrolaComponent/>}
                        </>
                    ) : checkbox.first && !checkbox.second && loading === false ? (
                        <>
                            {stage === 1 && <ZamerComponent
                                documents={documents}
                                setDocuments={setDocuments}
                                urlPath={urlPath}
                                setCheckbox={setCheckbox}
                            />}
                            {stage === 2 && <ZadatelComponent
                                urlPath={urlPath}
                                stage={stage}
                                foundApplicantIndex={foundApplicantIndex}
                                applicantArr={applicantArr}
                                setApplicantArr={setApplicantArr}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                            />}
                            {stage === 3 && <RozsahComponent
                                setCheckbox={setCheckbox}
                                urlPath={urlPath}
                            />}
                            {stage === 4 && <ZadostPovoleniStavbyOsnova5b
                                urlPath={urlPath}
                            />}
                            {stage === 5 && <Dokumentace
                                urlPath={urlPath}
                                documents={documents}
                                setDocuments={setDocuments}
                            />}
                            {stage === 6 && <PrilohyComponent
                                attachments={attachments}
                                setAttachments={setAttachments}
                                urlPath={urlPath}
                                stepsRef={stepsRef}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                                applicantArr={applicantArr}
                            />}
                            {stage === 7 && <PrijemceComponent
                                urlPath={urlPath}
                            />}
                            {stage === 8 && <KontrolaComponent/>}
                        </>
                    ) : !checkbox.first && checkbox.second && loading === false ? (
                        <>
                            {stage === 1 && <ZamerComponent
                                documents={documents}
                                setDocuments={setDocuments}
                                urlPath={urlPath}
                                setCheckbox={setCheckbox}
                            />}
                            {stage === 2 && <ZadatelComponent
                                urlPath={urlPath}
                                stage={stage}
                                foundApplicantIndex={foundApplicantIndex}
                                applicantArr={applicantArr}
                                setApplicantArr={setApplicantArr}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                            />}
                            {stage === 3 && <RozsahComponent
                                setCheckbox={setCheckbox}
                                urlPath={urlPath}
                            />}
                            {stage === 4 && <ZadostPovoleniStavbyOsnova4
                                urlPath={urlPath}
                            />}
                            {stage === 5 && <Dokumentace
                                urlPath={urlPath}
                                documents={documents}
                                setDocuments={setDocuments}
                            />}
                            {stage === 6 && <PrilohyComponent
                                attachments={attachments}
                                setAttachments={setAttachments}
                                urlPath={urlPath}
                                stepsRef={stepsRef}
                                setFoundApplicantIndex={setFoundApplicantIndex}
                                applicantArr={applicantArr}
                            />}
                            {stage === 7 && <PrijemceComponent 
                                urlPath={urlPath}
                            />}
                            {stage === 8 && <KontrolaComponent/>}
                        </>
                    ) : loading === false ? <>
                        {stage === 1 && <ZamerComponent
                            documents={documents}
                            setDocuments={setDocuments}
                            urlPath={urlPath}
                            setCheckbox={setCheckbox}
                        />}
                        {stage === 2 && <ZadatelComponent
                            urlPath={urlPath}
                            stage={stage}
                            foundApplicantIndex={foundApplicantIndex}
                            applicantArr={applicantArr}
                            setApplicantArr={setApplicantArr}
                            setFoundApplicantIndex={setFoundApplicantIndex}
                        />}
                        {stage === 3 && <RozsahComponent
                            setCheckbox={setCheckbox}
                            urlPath={urlPath}
                        />}
                        {stage === 4 && <Dokumentace
                            urlPath={urlPath}
                            documents={documents}
                            setDocuments={setDocuments}
                        />}
                        {stage === 5 && <PrilohyComponent
                            attachments={attachments}
                            setAttachments={setAttachments}
                            urlPath={urlPath}
                            stepsRef={stepsRef}
                            setFoundApplicantIndex={setFoundApplicantIndex}
                            applicantArr={applicantArr}
                        />}
                        {stage === 6 && <PrijemceComponent
                            urlPath={urlPath}
                        />}
                        {stage === 7 && <KontrolaComponent />}
                    </> :
                        <span>Načítám formulář...</span>}
                </Col>
                <Col lg={{ span: 4 }} xl={{ span: 4 }} className='d-flex justify-content-center justify-content-md-end d-none d-lg-block mb-md-5'>
                    {!isMobileView && 
                        <FormProgress
                            stepsRef={stepsRef}
                            isMobile={isMobileView}
                        />
                    }
                </Col>
            </Row>
        </Container>
    );
};

export default ZadostPovoleniStavbyMainSection;

ZadostPovoleniStavbyMainSection.propTypes = {
    stage: PropTypes.number.isRequired,
    checkbox: PropTypes.shape({
        'first': PropTypes.bool,
        'second': PropTypes.bool,
    }).isRequired,
    setCheckbox: PropTypes.func,
    isMobileView: PropTypes.bool,
    documents: PropTypes.object,
    setDocuments: PropTypes.func,
};