import React, { useContext, useEffect, useMemo, useState } from 'react';
import { counter, saved } from '../../constants/zadostState';
import '../../css/reusable.css';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { arrowRight, iconAutoSave, iconDownload } from '../../assets';
import { useGlobalState } from '../../constants/states';
import axios from 'axios';
import urlJoin from 'url-join';
import apiConfig from '../../config/api-config';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext.js';
import { createPdf, getDocumentationToken, isRequestOK } from '../../apiCalls/componentsApiCalls.js';
import { ProgressIndicator } from '../index';
import { canShowRemainingUploadCapacity, convertBytes, formatUrlWithToken, getTotalValue, getTotalValueFromUploads, handlePlExceptions } from '../../helperFunctions/helpers.js';
import { NEW_DOCUMENTATION, TOTAL_UPLOAD_LIMIT } from '../../constants/sharedConstants.js';
import { formSave } from '../../apiCalls/formApiCalls.js';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import UrednikCancelModal from '../Urednik/UrednikCancelModal.jsx';

const FormFooter = ({ setIsFinished, formNumber, setIsSuccessOrFailSubmission, receivedToken, currentlyUploading, buttonRef, setFinisherDetails, documents, isPlEmptyForUrednik = false }) => {
    const { token } = useContext(AuthContext);
    const { validationRef, saveFormRef, stagesArr, setStagesArr, id, uploadedBytes, waitingForPruvodniList, uploadProgressRef, isOfficer, setId } = useContext(FormContext);
    const [state, setState] = useGlobalState();
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [, forceUpdate] = useState(false);
    const [lastSaveTime, setLastSaveTime] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const cooldownPeriod = 3000;
    const saving = saved.value;
    const [averageProgress, setAverageProgress] = useState(0);
    const totalSizeValue = getTotalValueFromUploads(currentlyUploading, 'size') || 0;
    const uploadSize = convertBytes((totalSizeValue / currentlyUploading?.length) * averageProgress / 100, 2) || 0;
    const totalSize = convertBytes(totalSizeValue, 2) || 0;
    const remainingSizeInBytes = useMemo(() => (TOTAL_UPLOAD_LIMIT - Number(uploadedBytes) - getTotalValueFromUploads(currentlyUploading, 'size')), [currentlyUploading, uploadedBytes]);
    const isDocumentation = formNumber === 'documentation';
    const isExternalDocumentation = isDocumentation && Boolean(isOfficer);
    const isFinalStage = state?.stage === stagesArr?.length;
    const canRenderProgressIndicator = useMemo(() => Boolean(currentlyUploading?.length > 0), [currentlyUploading]) ?? false;
    const isInputError = React.useMemo(() => [...stagesArr].some(stg => (Number(stg?.stage) !== stagesArr?.length) && (stg?.error === true)), [stagesArr]);
    const isEveryThingChecked = React.useMemo(() => [...stagesArr]
        .filter(stg => Number(stg?.stage) !== stagesArr.length)
        .every(stg => (Number(stg?.stage) !== stagesArr?.length) && (stg?.seen === true)), [stagesArr]);
    const shouldDisableFormSubmitButton = useMemo(() =>
        ((isFinalStage || isExternalDocumentation) && (canRenderProgressIndicator || handlePlExceptions(waitingForPruvodniList, formNumber) || (isDocumentation && (isInputError || !isEveryThingChecked)) || isPlEmptyForUrednik)),
    [isFinalStage, isExternalDocumentation, canRenderProgressIndicator, waitingForPruvodniList, isDocumentation, isInputError, isEveryThingChecked, isPlEmptyForUrednik]);
    const decodedToken = jwtDecode(token);
    const urlPath = 'quick-save-api/documentation/save';
    const navigate = useNavigate();

    const calculateAverageProgress = () => {
        const progressValues = Object.values(uploadProgressRef?.current || {});
        const totalProgress = getTotalValue(progressValues);
        return progressValues.length > 0 ? Math.round(totalProgress / progressValues.length) : 0;
    };

    useEffect(() => {
        const updateProgress = () => {
            setAverageProgress(calculateAverageProgress());
        };

        updateProgress();

        const interval = setInterval(updateProgress, 100);
        return () => clearInterval(interval);
    }, [currentlyUploading]);

    useEffect(() => {
        const unsubscribe = saved.subscribe(() => {
            forceUpdate(update => !update);
        });

        return () => unsubscribe();
    }, []);

    const sendForm = async () => {
        try {
            const source = axios.CancelToken.source();
            // for now keep integrationsUrl for dokumentace until new endpoint
            // TODO check isExternalDocumentation
            let configKey = receivedToken ? 'validateAndPersistBaseUrl' : 'integrationsUrl';
            // TODO check isExternalDocumentation
            let param = isDocumentation ? `send/documentation/${id}` : receivedToken ? `build-intentions/submit-accompanying-document/${id}` : `send/${id}`;

            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                cancelToken: source.token,
            };

            if (receivedToken) {
                config.headers.shared = receivedToken;
            }

            const response = await axios.post(
                urlJoin(apiConfig[configKey], param),
                null,
                config);
            if (isRequestOK(response?.status)) {
                if (isDocumentation && setFinisherDetails) {
                    const { icsId, filePid, bppId } = response?.data?.data || {};
                    if (icsId, filePid, bppId) {
                        const data = { icsId, filePid, bppId };
                        const documentationToken = await getDocumentationToken(data, token, source);
                        const formattedUrl = documentationToken && formatUrlWithToken(filePid, documentationToken);
                        if (formattedUrl) {
                            setFinisherDetails(prev => ({ ...prev, urlToCopy: formattedUrl, icsId, filePid, bppId }));
                            setIsSuccessOrFailSubmission(prev => ({ ...prev, status: true }));
                        }
                    }
                } else {
                    setIsSuccessOrFailSubmission(prev => ({ ...prev, status: true }));
                }
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.log('Error', error);
                if (error.response?.status === 500) {
                    navigate('/sendingerror');
                }
            }
            // I don't know the error response structure so check below this way
            const errorMessage = isDocumentation 
                ? (error?.response?.data?.message ?? error?.response?.message ?? '') 
                : (error?.response?.data?.data ?? error?.response?.data ?? '');
            console.log('error message', errorMessage);
            setIsSuccessOrFailSubmission(prev => ({ ...prev, status: false, message: errorMessage }));
        }
    };

    useEffect(() => {
        if (!isOfficer)
            return;
        if (id) {
            (async () => {
                let data = JSON.stringify({
                    'applicationId': id,
                    'buildApplication': {
                        'documentations': Object.values(documents || {}).flat(),
                        'issrRequestId': decodedToken?.issrRequestId
                    },
                    'buildIntention': {
                        'title': { value: NEW_DOCUMENTATION?.get((false))?.title, dirty: false },
                        'filePid': decodedToken?.intentionFilePid,
                        'icsId': decodedToken?.intentionFileNumber,
                        'bppPid': decodedToken?.intentionBppPid,
                    }

                });

                await formSave(data, token, urlPath);
                sendForm();
                setIsSuccessOrFailSubmission(prev => ({ ...prev, status: null }));
                setIsFinished(true);
            })();
        }
    }, [id]);

    const createApplicationId = async () => {
        let data = JSON.stringify({
            'buildApplication': {
                'title': NEW_DOCUMENTATION.get((false))?.title,
            }
        });

        const response = await formSave(data, token, urlPath);
        if ((200 <= response?.status) && (response?.status < 300)) {
            console.log('START NOVE ZADOSTI 08', response.data);
            setId(response.data);
        }
    };


    const nextStage = async () => {
        if (isExternalDocumentation || isOfficer) {
            if (isOfficer) {
                createApplicationId();
                return;
            }

            sendForm();
            setIsSuccessOrFailSubmission(prev => ({ ...prev, status: null }));
            setIsFinished(true);
            return;
        }

        if (receivedToken && state.stage !== 4 && state.stage !== 3 && state.stage !== 2) {
            if (state.stage <= stagesArr.length - 1) {
                setState(prevState => {
                    return ({ ...prevState, stage: prevState.stage + 1 });
                });
            } else if (isFinalStage) {
                if (validationRef?.current?.validate()) {
                    sendForm();
                    setIsSuccessOrFailSubmission(prev => ({ ...prev, status: null }));
                    setIsFinished(true);
                } else {
                    window.scrollTo({
                        top: 500,
                        behavior: 'smooth',
                    });
                }
            }

            return;
        }

        if ((state.stage === 1 && !receivedToken) || (receivedToken && (state.stage === 4 || state.stage === 3 || state.stage === 2))) {
            if (validationRef?.current?.validate()) {
                setState(prevState => {
                    return ({ ...prevState, stage: prevState.stage + 1 });
                });
            } else {
                window.scrollTo({
                    top: 480,
                    behavior: 'smooth',
                });
            }
            return;
        }

        if (state.stage <= stagesArr.length - 1) {
            validationRef?.current?.validate();
            setState(prevState => {
                return ({ ...prevState, stage: prevState.stage + 1 });
            });
        } else if (isFinalStage) {
            const finalValidation = validationRef?.current?.validate();

            if (!isInputError && isEveryThingChecked && (isDocumentation || finalValidation)) {
                sendForm();
                setIsSuccessOrFailSubmission(prev => ({ ...prev, status: null }));
                setIsFinished(true);
                setStagesArr(prev => [...prev].map(stg => {
                    if (Number(stg.stage) === Number(state.stage)) {
                        return { ...stg, warning: false };
                    }
                    return stg;
                }));
            } else {
                setStagesArr(prev => [...prev].map(stg => {
                    if (Number(stg.stage) === Number(state.stage)) {
                        return { ...stg, warning: true };
                    }
                    return stg;
                }));
            }
        }
    };

    const prevStage = () => {
        if (state.stage > 1) {
            if (!receivedToken) {
                validationRef?.current?.validate();
            }
            setState(prevState => ({ ...prevState, stage: prevState.stage - 1 }));
        }
    };

    const handleSaveClick = () => {
        const currentTime = Date.now();
        if (currentTime - lastSaveTime > cooldownPeriod) {
            saveFormRef?.current?.saveForm();
            setLastSaveTime(currentTime);
            setIsButtonDisabled(true);

            setTimeout(() => {
                setIsButtonDisabled(false);
            }, cooldownPeriod);
        } else {
            console.log('Please, wait before saving again.');
        }
    };

    const handleCancelDocumentation = async () => {
        if (!isOfficer) {
            return;
        }
        const source = axios.CancelToken.source();
        const payload = {
            id: decodedToken?.issrRequestId,
            bppId: null,
            result: 'CANCEL',
            error: null
        };
        try {
            const cancelReponse = await axios.post(
                urlJoin(apiConfig.getAndQueryBaseUrl, 'projects/bpp-builder'),
                payload,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    cancelToken: source.token,
                });

            if (isRequestOK(cancelReponse?.status)) {
                navigate('/cancel');
            }
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled', error.message);
            } else {
                console.error(error);
            }
        }

    };

    return (
        <Container fluid className='p-3 p-md-5 d-flex justify-content-center' style={{ backgroundColor: 'white', padding: '24px 0' }}>
            <Row className='base-width d-flex align-items-center justify-content-between'>
                <Col xs={12} xl={4} className={`my-3 my-xl-0 d-flex ${isExternalDocumentation && 'justify-content-between'}`}>
                    {(!isExternalDocumentation && state.stage > 1)
                        &&
                        <button className='d-flex justify-content-between align-items-center button-outlined form-footer-btn' onClick={prevStage}>
                            <img src={arrowRight} style={{ rotate: '180deg', height: '1.5rem', marginLeft: '1rem' }} />
                            Zpět
                        </button>}
                    <button
                        className={`w-100 d-flex align-items-center justify-content-center button-solid form-header-btn form-footer-btn ${shouldDisableFormSubmitButton ? 'disabled' : ''}`}
                        onClick={() => {
                            if (shouldDisableFormSubmitButton) {
                                return;
                            }
                            nextStage();
                        }}
                        ref={el => { if (buttonRef) buttonRef.current['finisher'] = el; }}
                    >
                        {
                            (isExternalDocumentation ? 'Vložit dokumentaci' : stagesArr.length > 0 && stagesArr.map((item, index, array) =>
                                (state.stage === item.stage && index === array.length - 1) && receivedToken ? 'Vložit' :
                                    (state.stage === item.stage && index === array.length - 1) ? (isDocumentation ? 'Vložit dokumentaci' : 'Podat') :
                                        (state.stage + 1 === item.stage) ? item.name :
                                            ''
                            ))
                        }
                        <img src={arrowRight} style={{ height: '1.5rem', marginLeft: '1rem', filter: 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(42deg) brightness(104%) contrast(103%)' }} />
                    </button>
                </Col>
                {
                    isOfficer
                    &&
                    <Col xs={{ span: 12 }} xl={{ span: 4, offset: 3 }} >
                        <button onClick={() => setCancelModalOpen(true)} className='d-flex justify-content-center align-items-center text-center button-outlined form-footer-btn w-100' >
                            Zrušit
                        </button>
                    </Col>
                }
                {(remainingSizeInBytes > 0 && canRenderProgressIndicator) && (
                    <ProgressIndicator
                        type={'footer'}
                        averageProgress={averageProgress}
                        uploadSize={uploadSize}
                        totalSize={totalSize}
                        remainingSizeInBytes={remainingSizeInBytes}
                    />)}
                {!canRenderProgressIndicator && !isOfficer && (!saving
                    ?
                    <Col xs={12} md={8} xl={5} className='d-flex align-items-center justify-content-center justify-content-md-start my-2 my-md-0 ps-xl-4'>
                        <span>Ukládám formulář...</span>
                    </Col>
                    :
                    <Col xs={12} md={8} xl={5} className='d-flex align-items-center justify-content-center justify-content-md-start my-2 my-md-0 ps-xl-4'>
                        <img src={iconAutoSave} style={{ height: '1.25rem', marginRight: '1rem' }} />
                        <Container className='d-flex flex-column justify-content-center'>
                            <p>
                                <span className='form-footer-btn-text_first'>Automaticky uloženo </span>
                                <span className='form-footer-btn-text_second'>před {counter} sekundami. &nbsp;&nbsp;</span>
                                <span
                                    className={`form-footer-btn-text_main ${isButtonDisabled ? 'disabled' : 'enabled'}`}
                                    onClick={handleSaveClick}
                                >
                                    Uložit nyní
                                </span>
                            </p>
                            {canShowRemainingUploadCapacity(formNumber) && (
                                remainingSizeInBytes > 0 ? (<p className='upload-indicator-footer secondary'>{`Možno nahrát ještě ${convertBytes(remainingSizeInBytes) ?? '0 MB'}`}</p>)
                                    : (<p className='upload-indicator-footer secondary'>Datový limit dokumentace dosažen, další soubory již není možné nahrát.</p>)
                            )}
                        </Container>
                    </Col>)}


                {
                    !isDocumentation
                    &&
                    <Col xs={12} md={4} xl={{ span: 3 }} className='d-flex align-items-center justify-content-center justify-content-md-end my-2 my-md-0' style={{ cursor: 'pointer' }}>
                        <img src={iconDownload} style={{ height: '1.25rem', marginRight: '1rem' }} />
                        <p style={{ fontSize: '0.938rem', fontWeight: '500', lineHeight: '1.464rem', letterSpacing: '0.018rem', color: '#2362A2', textDecoration: 'underline' }} onClick={() => createPdf(formNumber, id, receivedToken, token)}>
                            Stáhnout náhled PDF k tisku
                        </p>
                    </Col>
                }
            </Row>

            <UrednikCancelModal
                cancelModalOpen={cancelModalOpen}
                setCancelModalOpen={setCancelModalOpen}
                confirmOperation={handleCancelDocumentation}
            />
        </Container>
    );
};


FormFooter.propTypes = {
    setIsFinished: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    formNumber: PropTypes.string,
    receivedToken: PropTypes.string,
    setIsSuccessOrFailSubmission: PropTypes.func,
    averageProgress: PropTypes.number,
    currentlyUploading: PropTypes.arrayOf(Object),
    buttonRef: PropTypes.objectOf(Array),
    setFinisherDetails: PropTypes.func,
    documentsToTrack: PropTypes.any,
    documents: PropTypes.object,
};

export default FormFooter;

